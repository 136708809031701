/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { mapEdgesToNodes } from "../lib/helpers";

import FigureGrid from "../components/figureGrid";
import Layout from "../components/layout";
import Section from "../components/section";
import SEO from "../components/seo";

export const query = graphql`
    query ProjectsPageQuery {
        caseStudies: allSanityCaseStudy(
            filter: { slug: { current: { ne: null } }, startDate: { ne: null } }
        ) {
            edges {
                node {
                    id
                    title
                    banner {
                        ...ImageWithPreview
                    }
                    subtitle: technology
                    slug {
                        current
                    }
                }
            }
        }
    }
`;

const Projects = props => {
    const { data, errors } = props;

    if (errors) {
        return <Layout>Error</Layout>;
    }
    console.log((data || {}).caseStudies);
    const site = (data || {}).site;
    const caseStudyNodes = (data || {}).caseStudies ? mapEdgesToNodes(data.caseStudies) : [];

    let leftSide = [];
    let rightSide = [];
    for (let i = 0; i < caseStudyNodes.length; i++) {
        i % 2 === 0 ? leftSide.push(caseStudyNodes[i]) : rightSide.push(caseStudyNodes[i]);
    }

    const projectsList = { left: leftSide, right: rightSide };

    console.log(projectsList);

    return (
        <Layout>
            <SEO title="Projects" />
            <Section name="projects" hero>
                <h1 sx={{ pb: 6, variant: "text.title.main" }}>My Projects</h1>
                <FigureGrid list={projectsList} showButton showInfo />
            </Section>
        </Layout>
    );
};

export default Projects;
